import React, { useState, useContext, useCallback } from "react";
// import { signOut } from "../auth";
import { EventContext } from "../context/EventContext";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import NavTreeNode from "./NavTreeNode";
import { CLIENT, GTN } from "../constants";

const MainNav = () => {
	const [selectedNode, setSelectedNode] = useState(null);
	const { eventID, hideMobileNav, setHideMobileNav, categories } =
		useContext(EventContext);
	const { signOut } = useContext(AuthContext);

	// Render nav node categories for each gender category
	const renderNodes = useCallback(() => {
		if (!categories) return;

		const categoriesArray =
			CLIENT === GTN
				? Object.values(categories).sort()
				: Object.keys(categories).sort();

		if (CLIENT === GTN) {
			return (
				<div className="treeview">
					<ul className="treeview-nodes">
						<NavTreeNode
							selectedNode={selectedNode}
							setSelectedNode={setSelectedNode}
						/>
						{categoriesArray.map((gender, i) => {
							return (
								<NavTreeNode
									key={i}
									gender={gender}
									selectedNode={selectedNode}
									setSelectedNode={setSelectedNode}
									productCategory={categoriesArray[i]}
								/>
							);
						})}
					</ul>
				</div>
			);
		}
		return (
			<div className="treeview">
				<ul className="treeview-nodes">
					<NavTreeNode
						selectedNode={selectedNode}
						setSelectedNode={setSelectedNode}
					/>

					{categoriesArray.map((gender, i) => {
						return (
							<NavTreeNode
								key={i}
								gender={gender}
								selectedNode={selectedNode}
								setSelectedNode={setSelectedNode}
							/>
						);
					})}
				</ul>
			</div>
		);
	}, [categories, selectedNode, setSelectedNode, CLIENT]);

	// Render the navigation pane
	const renderNav = useCallback(() => {
		return (
			<nav className={`mainNav loaded ${hideMobileNav ? "hidden" : ""}`}>
				{renderNodes()}
				<ul className="nav-links">
					<li>
						<Link to={`/${eventID}/`}>Home</Link>
					</li>
					<li>
						<Link to={`/${eventID}/cart`}>Cart</Link>
					</li>
					{/* <li onClick={() => forgotPassword()}> */}
					{/*   <label>Account</label> */}
					{/* </li> */}
					{/* TODO this should be displayed only if screen size is mobile */}
					<li onClick={() => signOut()} className="context-menu">
						<label>Logout</label>
					</li>
				</ul>
			</nav>
		);
	}, [eventID, renderNodes, hideMobileNav, signOut]);

	return (
		<React.Fragment>
			<label
				style={{ cursor: "pointer" }}
				className="hamburger"
				onClick={() => setHideMobileNav(!hideMobileNav)}
			/>
			{renderNav()}
		</React.Fragment>
	);
};

export default MainNav;
