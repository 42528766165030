import React, { useEffect, useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { EventContext } from "../context/EventContext";

const GoodBye = () => {
  const location = useLocation();
  const { eventID, setEventID, existingEvent } = useContext(EventContext)

  useEffect(() => {
    document.body.className = "fourOhFour";
    return () => {
      document.body.className = "";
    };
  }, []);

  // Set the event ID from the URL path
  useEffect(() => {
    if (!location) return;
    const event = location.pathname.split("/")[1];
    setEventID(decodeURI(event));
  }, [setEventID, location]);

  // Detemine whether the url is the base event path for redirect to login
  const baseEventURL = () => {
    const path = location.pathname.split("/");
    return path.length < 3 || path[2].length <= 0;
  };

  if (existingEvent == null) return null;
  if (existingEvent && baseEventURL()) return <Redirect to={`/${eventID}/login`} />;

  return (
    <React.Fragment>
      <div id="fourOhFour">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>The specified file was not found on this website.</p>
        <p>Please check the URL for mistakes and try again.</p>
      </div>
    </React.Fragment>
  );
};
export default GoodBye;
