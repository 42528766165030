export const CLIENT = process.env.REACT_APP_CLIENT_CODE;
export const CPG = "cpg";
export const SMS = "sms";
export const GTN = "gtn";

const CPG_ONLY = [CPG].includes(CLIENT);
const SMS_ONLY = [SMS].includes(CLIENT);

/* FEATURE FLAGS */
export const USE_MASTER_LIST = CPG_ONLY;
export const HIDE_SINGLE_SIZE = SMS_ONLY;
