import React, { useState, useEffect } from "react";

const ErrorBoundary = (props) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setHasError(false);
    setError(null);
  }, [props.children]);

  if (hasError) {
    return (
      <div className="error-screen">
        <h2>An error has occurred</h2>
        <h4>{error && error.toString()}</h4>
      </div>
    );
  }
  return props.children;
};

export default ErrorBoundary;
