import React, { useEffect, useContext, useCallback } from "react";

import PayPal from "./PayPal";
import Stripe from "./Stripe";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import alertImage from "../images/alert-octagon.png";

import { getPriceString } from "../helpers";

const CartSummary = ({ trigger }) => {
  const { maxOverage,
    overBudgetRules,
    unlimitedOverage,
    unlimitedBudget,
    dropShipCharge,
    forceDropShip,
    customUnits } = useContext(EventContext);

  const { cartState,
    submitting, submitOrder,
    scrollToCart, scrollToAddress, scrollToFirstError,
    dropShip, setDropShip,
    overMax, setOverMax,
    overBudget, setOverBudget,
  } = useContext(CartContext);

  useEffect(() => {
    if (!cartState) return;
    const overage = cartState.total - cartState.budget - cartState.payPalApplied
      + (dropShip ? dropShipCharge : 0);
    setOverBudget(cartState.total + (dropShip ? dropShipCharge : 0) > (cartState.budget + cartState.payPalApplied));
    const overageMatters =
      overBudgetRules === "paypal"
      || overBudgetRules === "stripe"
      || maxOverage >= 0;
    setOverMax(overage > 0 && overage > maxOverage && overageMatters);
  }, [overBudgetRules, cartState, maxOverage, setOverBudget, setOverMax, dropShip, dropShipCharge]);

  const getOverageMessage = useCallback(() => {
    const message =
      !!maxOverage
        ? overBudgetRules === "paypal"
          ? "Please settle your remainder with PayPal"
          : overBudgetRules === "stripe"
            ? "Please settle your remainder with a credit card"
            : overMax && !unlimitedOverage
              ? `You are over your max overage allowance of $${maxOverage}.`
              : "Please visit the pro shop on tournament day to settle your account."
        : "You are not allowed any overage on your budget. Please remove some items from your cart to stay within budget.";

    return message;
  }, [overMax, maxOverage, unlimitedOverage, overBudgetRules]);

  const renderClubPickupButton = useCallback(() => {
    return (
      <div>
        <button
          onClick={() => {
            scrollToAddress();
            setDropShip(false)
          }}
          style={{ background: !dropShip ? "green" : "gray" }}
          className="button">
          {`Pickup at the club`}
        </button>
      </div>
    );
  }, [dropShip, scrollToAddress, setDropShip]);

  const renderDropShipButton = useCallback(() => {
    return (
      <div>
        <button
          onClick={() => {
            scrollToAddress();
            setDropShip(true)
          }}
          style={{ background: !!dropShip ? "green" : "gray" }}
          className="button">
          {`Drop ship to your address (+$${dropShipCharge})`}
        </button>
      </div>
    );
  }, [dropShip, dropShipCharge, scrollToAddress, setDropShip]);

  const renderSubmitButton = useCallback(() => {
    const emptyCart = !cartState.products || !Object.keys(cartState.products).length
    return (
      <div>
        <button
          onClick={() => {
            trigger().then((verified) => {
              if (verified) {
                scrollToCart();
                setTimeout(() => submitOrder(), 700);
              } else {
                scrollToFirstError();
              }
            });
          }}
          disabled={overMax || emptyCart}
          className="button">
          {submitting ? "Submitting Order..." : "Submit order"}
        </button>
      </div>
    );
  }, [overMax, cartState.products, scrollToCart, scrollToFirstError, submitOrder, submitting, trigger]);

  const renderDeliveryOptionsMessage = useCallback(() => {
    const message =
      forceDropShip
        ? "For this event, the only delivery option is to drop ship to your address"
        : dropShipCharge < 0
          ? "For this event, the only delivery option is to pick up your items at the club."
          : "";
    return (
      <em>{message}</em>
    );
  }, [forceDropShip, dropShipCharge]);

  // If there's only one delivery option available, be explicit
  const renderDeliveryOptions = useCallback(() => {
    return (
      <div>
        {/* <Arrow/> */}
        <h2>Delivery Options</h2>
        {!forceDropShip && renderClubPickupButton()}
        {dropShipCharge >= 0 && renderDropShipButton()}
        {renderDeliveryOptionsMessage()}
      </div>
    );
  }, [renderClubPickupButton, renderDropShipButton, renderDeliveryOptionsMessage, dropShipCharge, forceDropShip]);

  const renderBudgetSummary = useCallback(() => {
    return (
      <div className="budget-summary">
        <h3>
          Subtotal{" "}
          <span className="subtotal">{getPriceString(cartState.total, customUnits)}</span>
        </h3>
        {!unlimitedBudget && (
          <h3 className={`Budget ${dropShip ? "" : "greyBorder"}`}>
            Budget{" "}
            <span className="subtotal">{getPriceString(cartState.budget, customUnits)}</span>
          </h3>
        )}
        {(unlimitedBudget || overBudgetRules === "paypal") && (
          <h3 className="greyBorder">
            Paid via PayPal{" "}
            <span className="subtotal">{getPriceString(cartState.payPalApplied)}</span>
          </h3>
        )}
        {dropShip && (
          <h3 className="greyBorder">
            Drop Ship Charge{" "}
            <span className="subtotal">{getPriceString(dropShipCharge)}</span>
          </h3>
        )}
      </div>
    );
  }, [cartState.budget, cartState.payPalApplied, cartState.total, dropShip, dropShipCharge, overBudgetRules, customUnits, unlimitedBudget]);

  const renderOverBudget = useCallback(() => {
    const message = getOverageMessage();
    return (
      <div>
        {!unlimitedBudget && <div className="warning">
          <img src={alertImage} alt="Over budget" style={{ paddingBottom: "20px" }} />
          {message}
        </div>}
        {overBudgetRules === "paypal" ? <PayPal /> : null}
        {overBudgetRules === "stripe" ? <Stripe /> : null}
      </div>
    )
  }, [overBudgetRules, getOverageMessage, unlimitedBudget]);

  const renderBalance = useCallback(() => {
    const subtotal = cartState.budget - cartState.total + cartState.payPalApplied;
    // Add the drop ship charge if necessary
    const total = unlimitedBudget
      ? getPriceString(cartState.total, customUnits)
      : dropShip
        ? customUnits
          // If theres custom units and drop ship, only charge drop ship
          ? getPriceString(dropShipCharge)
          // If using dollars, subtract the drop ship charge
          : getPriceString(subtotal - dropShipCharge)
        : getPriceString(subtotal, customUnits);

    // Don't show the total if the order is ready for submission
    if (!overBudget) return (
      <div
        className="note"
      >
        Please submit your order
      </div>
    )

    return (
      <div>
        <h3>{unlimitedBudget || overBudget ? "Total" : "Remaining Balance"}</h3>
        <div
          className={overBudget && !unlimitedBudget
            ? "red balance balanceWarning"
            : "grey balance balanceWarning"
          }>
          {total}
        </div>
        {renderOverBudget()}
        <br />
      </div>
    );
  }, [renderOverBudget, cartState, dropShip, dropShipCharge, overBudget, customUnits, unlimitedBudget]);

  if (!cartState) return null;

  return (
    <React.Fragment>
      <h2>Summary</h2>
      {renderBudgetSummary()}
      {renderBalance()}
      {renderDeliveryOptions()}
      <br />
      {renderSubmitButton()}
    </React.Fragment>
  );
};

export default CartSummary;
