import { useEffect, useState, useContext, useCallback } from "react";
import { functions } from "../firebase";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import { ADD_PAYPAL } from "../reducers/CartReducer";
import { PayPalButton } from "react-paypal-button-v2";

const createPayPalOrder = functions.httpsCallable("httpsOnCall-createPayPalOrder");

function purgeNaNRecursive(obj) {
  if (Array.isArray(obj)) {
    return obj
      .map(val => purgeNaNRecursive(val))
      .filter(val => !Number.isNaN(val));
  } else if (typeof obj === 'object' && obj !== null) {
    let newObj = {};
    for (let prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        let value = obj[prop];
        if (!Number.isNaN(value)) {
          newObj[prop] = purgeNaNRecursive(value);
        }
      }
    }
    return newObj;
  } else {
    return obj;
  }
}

const PayPal = () => {
  const [loading, setLoading] = useState(false);
  const [fullCartProducts, setFullCartProducts] = useState({});
  const { products, dropShipCharge, payPalPayee, customUnits } = useContext(EventContext);
  const { cartState, cartDispatch, dropShip } = useContext(CartContext);

  const createOrder = useCallback(() => {
    setLoading(true);
    const cartProducts = Object.values(fullCartProducts);
    // Creates a PayPal Order with Firebase functions and PayPal client API
    return createPayPalOrder(
      {
        cartProducts: purgeNaNRecursive(cartProducts),
        budget: cartState.budget + cartState.payPalApplied,
        total: cartState.total,
        dropShipCharge: dropShip ? dropShipCharge : 0,
        payee: payPalPayee,
        customUnits,
      }).then(({ data }) => {
        setLoading(false);
        return data.result.id;
      }).catch((err) => {
        console.log(`Error creating PayPal order: ${err}`);
        setLoading(false);
      });
  }, [fullCartProducts, cartState, dropShip, dropShipCharge, payPalPayee, customUnits]);

  const renderPaypalButton = useCallback(() => {
    if (!window.paypal) return;
    return (
      <PayPalButton
        disabled={loading}
        style={{ color: "blue", shape: "pill" }}
        fundingSource={window.paypal.FUNDING.PAYPAL}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            const amountPaid = details.purchase_units.reduce((sum, unit) => {
              return sum + unit.amount.value;
            }, 0);
            console.log(`Paid ${amountPaid} with PayPal`);
            cartDispatch({ type: ADD_PAYPAL, amount: amountPaid });
          });
        }}
        onError={(error) => {
          alert(`There was an error with the PayPal transaction: ${error}`);
        }}
        createOrder={(data, actions) => createOrder()}
      />
    );
  }, [cartDispatch, createOrder, loading]);

  // Populate cart products with full product data
  useEffect(() => {
    if (!cartState.products) return;
    const cartProducts = Object.keys(cartState.products).reduce((result, key) => {
      const cartProduct = cartState.products[key];
      const xID = cartProduct.product;
      return {
        ...result,
        [key]: {
          ...cartProduct,
          product: products[xID],
        }
      }
    }, {});
    setFullCartProducts(cartProducts);
  }, [products, cartState]);

  return (
    <div>
      {renderPaypalButton()}
      {/* <div ref={paypal}/> */}
    </div>
  );
};

export default PayPal;
