import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import { forgotPassword } from "../auth";
import { EventContext } from "../context/EventContext";

const ForgotPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const errorRef = useRef("");
  const history = useHistory();
  const { eventID } = useContext(EventContext);

  useEffect(() => {
    document.body.className = "forgotPassword";
    return () => {
      document.body.className = "";
    };
  }, []);

  const submitHandler = useCallback((event) => {
    event.preventDefault();
    const { email } = event.target.elements;
    setSendingEmail(true);
    forgotPassword(email.value).then(() => {
      errorRef.current = "Email sent successfully!"
      alert("Email sent successfully!");
      setEmailSent(true);
      setSendingEmail(false);
    }).catch((err) => {
      console.log(`Error sending email: ${err}`);
      errorRef.current = err;
      setSendingEmail(false);
    });
  }, []);

  // If the email was sent, send them back to the login page
  useEffect(() => {
    if (emailSent && !!eventID) {
      history.push(`/${eventID}/login/`);
    }
  }, [emailSent, history, eventID]);

  return (
    <div className="main">
      <h1>Forgot Password</h1>
      <p>To reset your password, please enter your email.</p>
      <p>VRLink will send the password reset instructions to the email address for this account.</p>

      <form
        name="loginForm"
        onSubmit={submitHandler}
        autoComplete="off"
      >
        {/* retry message */}
        <div className="retry-message">
          <label>{errorRef.current}</label>
        </div>
        <input
          name="email"
          type="text"
          className="validated-field"
          placeholder="Email"
          required
          autoFocus
        />{" "}
        <br/>
        {/* submit */}
        <button type="submit" className="button" disabled={sendingEmail}>
          Reset Password
          <span></span>
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
