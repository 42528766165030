import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";
import { CLOSE_CART } from "../reducers/CartReducer";

const CartMessage = () => {
  const { cartDispatch } = useContext(CartContext);

  return (
    <div className="cartMessageContainer">
      <span className="cartUIMessage"></span>
      <span className="checkmark"></span> Added to Cart
      <span
        onClick={() => cartDispatch({ type: CLOSE_CART })}
        className="close"
      ></span>
    </div>
  );
};

export default CartMessage;
