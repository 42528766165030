import { auth, EmailAuthProvider } from "./firebase";
import { signInToEventWithPassword } from "./firestore";

// Sign in with Firebase
export const signIn = async (email, password) => {
  const credential = await auth.signInWithEmailAndPassword(email, password);
  const user = credential.user;
  return user;
};

// Create a user in Firebase Auth
export const createUserWithEmailAndPassword = async (email, password) => {
  console.log("Creating user in Firebase");
  try {
    const PADDING = "xxxxxx";
    if (password.length < 6) password = password + PADDING;
    const userCredential = await auth.createUserWithEmailAndPassword(email, password);
    return {
      email: userCredential.user.email,
      id: userCredential.user.uid
    };
  } catch(err) {
    throw new Error(`There was a problem signing in. Please contact the site administrator:\n\n${err}`);
  };
};

export const forgotPassword = async (email) => {
  console.log(`Sending email to ${email}`);
  return auth.sendPasswordResetEmail(email);
};

export const updatePassword = async (password) => {
  try {
    await auth.currentUser.updatePassword(password);
    console.log("Password changed successfully!");
    return true;
  } catch(err) {
    alert(`Error setting password: ${err}`);
    return false;
  };
};

// Confirm the current user's password
export const confirmCurrentPassword = async (password) => {
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    password
  );

  return user.reauthenticateWithCredential(credential).then(() => {
    return true;
  }).catch((error) => {
    console.log(`Error reauthenticating user: ${error}`)
    return false;
  });
};

// Sign in from email link
export const checkForEmailLink = async () => {
  console.log("checking for email link")
  // Confirm the link is a sign-in with email link.
  if (auth.isSignInWithEmailLink(window.location.href)) {
    const email = window.prompt('Please provide your email for confirmation');
    // The client SDK will parse the code from the link for you.
    try {
      const result = await auth.signInWithEmailLink(email.toLowerCase(), window.location.href);
      const user = result.user;
      const newUser = result.additionalUserInfo.isNewUser;
      return {...user, isNew: newUser};
    } catch(error) {
      throw new Error(`Couldn't signing in with email link: ${error.message}`);
    };
  }
}

// Sign in with Firebase to a specific event
export const signInToEvent = async (email, password, eventID) => {
  // As per request, users will be signing in with a site-wide password
  // const credential = await auth.signInWithEmailAndPassword(email, password);
  // const user = credential.user;

  const user = await signInToEventWithPassword(email, password, eventID);
  return user;
};

export const signOut = () => {
  return auth.signOut();
};
