import { useState } from "react";

const MonogramConfig = ({
  monogramTopText,
  monogramBottomText,
  monogramStyle,
  onSave,
  onCancel
}) => {
  const MIXED_BLOCK = "MIXED_BLOCK";
  const UPPERCASE_BLOCK = "UPPERCASE_BLOCK";
  const SCRIPT = "SCRIPT";

  const [topText, setTopText] = useState(monogramTopText ?? "");
  const [bottomText, setBottomText] = useState(monogramBottomText ?? "");
  const [fontStyle, setFontStyle] = useState((monogramStyle && monogramStyle !== "") ? monogramStyle : MIXED_BLOCK);

  const handleSave = () => {
    if (!topText) {
      alert("Please complete the monogram configuation");
      return;
    }
    onSave({ topText, bottomText, fontStyle });
  };

  const textConfig = () => {
    return (
      <div className="embroideryText">
        <div>
          <input
            type="text"
            value={topText}
            onChange={(e) => setTopText(e.target.value)}
          />
        </div>
        <div>
          <input
            type="text"
            value={bottomText}
            onChange={(e) => setBottomText(e.target.value)}
          />
        </div>
        <div>
          <select
            value={fontStyle}
            onChange={(e) => setFontStyle(e.target.value)}
          >
            <option value={MIXED_BLOCK}>Block (Mixed case)</option>
            <option value={UPPERCASE_BLOCK}>Block (Upper case)</option>
            <option value={SCRIPT}>Script</option>
          </select>
        </div>
      </div>
    );
  };

  return (
    <div className="monogramConfigModal">
      <div className="embroideryTextContainer">
        <div className="embroideryLabels">
          <label>Top text</label>
          <label>Bottom text</label>
          <label>Style</label>
        </div>
        {textConfig()}
      </div>
      <div className="button-container">
        <button onClick={handleSave}>Save</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default MonogramConfig;
