import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";
import { EventProvider } from "./context/EventContext";
import { CartProvider } from "./context/CartContext";
import { DropShipProvider } from "./context/DropShipContext";
import { PrivateRoute } from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Login from "./components/Login";
import Welcome from "./components/Welcome";
import Promo from "./components/Promo";
import StoreCategory from "./components/StoreCategory";
import ProductDetail from "./components/ProductDetail";
import Checkout from "./components/Checkout";
import ThankYou from "./components/ThankYou";
import Goodbye from "./components/Goodbye";
import OutOfService from "./components/OutOfService";
import InvalidEvent from "./components/InvalidEvent";
import NotFound from "./components/NotFound";
import SetPassword from "./components/SetPassword";
import ForgotPassword from "./components/ForgotPassword";
import Account from "./components/Account";
import "./css/index.scss";

const Router = () => (
  <BrowserRouter>
    <EventProvider>
      <AuthProvider>
        <DropShipProvider>
          <CartProvider>
            <ScrollToTop>
              <Switch>
                <Route exact path="/:eventID?" component={NotFound} />
                <Route exact path="/:eventID/login" component={Login} />
                <Route exact path="/account/changePassword/" component={Account} />
                <Route exact path="/account/setPassword" component={SetPassword} />
                <Route exact path="/help/forgotPassword" component={ForgotPassword} />
                <PrivateRoute exact path="/:eventID/welcome" component={Welcome} />
                <PrivateRoute exact path="/:eventID/promo" component={Promo} />
                <PrivateRoute
                  exact
                  path="/:eventID/store"
                  component={StoreCategory}
                />
                <PrivateRoute
                  exact
                  path="/:eventID/store/product/:styleCode/:colorCode?"
                  component={ProductDetail}
                />
                <PrivateRoute
                  exact
                  path="/:eventID/store/:category/:subCategory?"
                  component={StoreCategory}
                />
                <PrivateRoute exact path="/:eventID/cart" component={Checkout} />
                <PrivateRoute exact path="/:eventID/checkout" component={Checkout} />
                <PrivateRoute exact path="/:eventID/checkout/paypal" component={Checkout} />
                <PrivateRoute exact path="/:eventID/thankyou" component={ThankYou} />
                <Route exact path="/:eventID/goodbye" component={Goodbye} />
                <Route exact path="/:eventID?/outofservice/:error?" component={OutOfService} />
                <Route exact path="/:eventID?/invalid/:error?" component={InvalidEvent} />
                <Route component={NotFound} />
              </Switch>
            </ScrollToTop>
          </CartProvider>
        </DropShipProvider>
      </AuthProvider>
    </EventProvider>
  </BrowserRouter>
);

export default Router;
