import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { EventContext } from "../context/EventContext";

const GoodBye = () => {
  const { eventID } = useContext(EventContext);

  useEffect(() => {
    document.body.className = "goodbye";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <div className="main">
        <div className="logo"></div>
        <h1>
          Thank you for
          <br />
          shopping with us.
        </h1>

        <div className="shippingInfo">
          <p>
            <Link to={`/${eventID}/login`}>Login again.</Link>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
export default GoodBye;
