import React, { useEffect, useContext, useCallback } from "react";
import Header from "./Header";
import { EventContext } from "../context/EventContext";
import { AuthContext } from "../context/AuthContext";
import { CartContext } from "../context/CartContext";

const ThankYou = () => {
  const { customer, logo, brandColor, eventName } = useContext(EventContext);
  const { user } = useContext(AuthContext);
  const { dropShip } = useContext(CartContext);

  useEffect(() => {
    document.body.className = "thankyou";
    return () => {
      document.body.className = "";
    };
  }, []);

  const renderAddress = useCallback(() => {
    if (!customer) return;
    const shippedTo = !!dropShip ? user.address : customer;
    const name = !!dropShip
      ? `${user.address.firstName} ${user.address.lastName}`
      : customer.name;
    if (!shippedTo) return;
    return (
      <address>
        <strong>{name}</strong>
        {shippedTo.street}
        {shippedTo.street2 ? (
          <br />
        ) : null}
          {shippedTo.street2}
        <br />
        {shippedTo.city}, {shippedTo.state} {shippedTo.zip}
      </address>
    );
  }, [customer, dropShip, user.address]);

  return (
    <React.Fragment>
      <Header />

      <div className="main">
        <div className="check" style={{ backgroundColor: brandColor }}></div>
        <h1>
          {user.firstName}{user.firstName ? "," : null}
          {user.firstName ? <br /> : null}
          Thank you for
          <br />
          your order!
        </h1>
        <img src={logo} alt=""/>

        <div className="shippingInfo">
          <p>Your package will be shipped to:</p>
          {renderAddress()}
        </div>

        <p>You will receive an email confirmation of your order.</p>
        <p>
          <strong>See you at the {eventName}</strong>
        </p>
      </div>
    </React.Fragment>
  );
};
export default ThankYou;
