import React, { useState, useContext, useEffect, useMemo } from "react";
import { getBitmap, capitalizeFirstLetter } from "../helpers";
import { EventContext } from "../context/EventContext";
import { RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from "@material-ui/core";

const EmbroideryOption = ({ option, setSelectedEmbroidery }) => {
	const [selected, setSelected] = useState(false);
	const { isMonogram } = useContext(EventContext);
	const monogram = useMemo(
		() => isMonogram(option.tape),
		[isMonogram, option.tape]
	);

	useEffect(() => {
		if (selected) {
			setSelectedEmbroidery((selectedEmbroidery) => [
				...selectedEmbroidery,
				option,
			]);
		} else {
			setSelectedEmbroidery((selectedEmbroidery) =>
				selectedEmbroidery.filter((emb) => emb !== option)
			);
		}
	}, [selected, setSelectedEmbroidery, option]);

	if (!option?.tape || !option?.position?.name || !option?.position?.code) {
		return;
	}
	const tapeImage = getBitmap(option.tape);
	const name = capitalizeFirstLetter(option.position?.name);
	const logoType = monogram ? "Monogram" : "Logo";

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				marginBottom: 8,
			}}
			id="embroidery"
		>
			<FormControl>
				<FormLabel id="embroidery-group-label">{name}</FormLabel>
					<RadioGroup
						value={selected.toString()}
						onChange={() => setSelected(!selected)}
						>
						<FormControlLabel
							value="true"
							control={<Radio />}
							label={logoType}
							style={{ cursor: "pointer" }}
						/>
						<FormControlLabel
							value="false"
							control={<Radio />}
							label={`No ${logoType}`}
							style={{ cursor: "pointer" }}
						/>
					</RadioGroup>
				</FormControl>
			<div
				style={{ marginLeft: 10 }}
			>
				{!monogram && tapeImage && <img alt="" src={tapeImage} />}
			</div>
		</div>
	);
};

const Embroidery = ({ options, setSelectedEmbroidery }) => {
	if (!options) {
		return null;
	}

	return (
		<div>
			{options.map((option, index) => (
				<EmbroideryOption
					key={index}
					option={option}
					setSelectedEmbroidery={setSelectedEmbroidery}
				/>
			))}
		</div>
	);
};

export default Embroidery;
