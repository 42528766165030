import React, { useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import { AuthContext } from "../context/AuthContext";
import CartPopup from "./CartPopup";
// import { signOut } from "../auth";
import { getPriceString } from "../helpers";
import { BsHouseDoor } from "react-icons/bs"

const Header = () => {
  const { eventID,
    hideMobileNav,
    setHideMobileNav,
    logo,
    eventName,
    brandColor,
    dropShipCharge,
    customUnits,
    unlimitedBudget
  } = useContext(EventContext);
  const { signOut } = useContext(AuthContext);
  const { dropShip, cartState } = useContext(CartContext);
  const headerRef = useRef();

  if (!cartState) return null;

  // Calculate total and balance based on dropShip state
  const total = cartState.total + (dropShip && !customUnits ? dropShipCharge : 0);
  const balance = cartState.budget + cartState.payPalApplied - total;
  const numProducts = !!cartState.products ? Object.keys(cartState.products).length : 0;

  return (
    <div ref={headerRef} className="header fade-in">
      <div className="welcomeHeader">
        <div className="marquee">Welcome to the {eventName}!</div>
        <span className="logout" style={{ background: brandColor }}>
          <label onClick={() => signOut()}>Logout</label>
        </span>
      </div>

      <div className="storeHeader">
        {logo ?
        <Link
          to={`/${eventID}/welcome`}
          style={{ backgroundImage: `url(${logo})` }}
          className="logo"
        />
         :
        <Link to={`/${eventID}/welcome`}>
         <BsHouseDoor className="logo" />
        </Link>}
        <span className="eventName">{eventName}</span>
        <span className="budgetInfo active">
          Total: <strong>{getPriceString(total, customUnits)}</strong>
          {!unlimitedBudget && " | Balance: "}
          <strong>{!unlimitedBudget && getPriceString(balance, customUnits)}</strong>
        </span>
        <Link
          to={`/${eventID}/cart`}
          className="cart"
        >
          {cartState ? (
            <span className="cartJewel">{numProducts}</span>
          ) : null}
        </Link>
        <span onClick={() => signOut()} className="logout">
          <label style={{ cursor: "pointer" }}>Logout</label>
        </span>
        <label
          style={{ cursor: "pointer" }}
          className="hamburger"
          onClick={() => setHideMobileNav(!hideMobileNav)}
        />

        {cartState && cartState.open ? (
          <CartPopup headerRef={headerRef}/>
        ) : null}
      </div>
      <div className="thankYouHeader">...</div>
    </div>
  );
};

export default Header;
