import React from 'react';
import { render } from 'react-dom';
import ErrorBoundary from './components/ErrorBoundary';
import Router from './Router';

render(
  <ErrorBoundary>
    <Router />
  </ErrorBoundary>
  , document.querySelector("#root"));
